import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/quemSomos',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/termoDeUso',
    name: 'userTerm',
    component: () => import('../views/TermoView.vue')
  },
  {
    path: '/politicaDePrivacidade',
    name: 'politica',
    component: () => import('../views/PoliticaView.vue')
  },
  {
    path: '/pt/politica.php',
    name: 'politica.php',
    component: () => import('../views/politicaOldView.vue')
  },
  {
    path: "/equipamentos_cftv_homologados.pdf",
    name:'cftvHomologados',
    component: () => import('../views/CftvHomologadosView.vue')
  },
  {
    path: "/requisitos.pdf",
    name: 'requisitos',
    component: () => import('../views/RequisitosView.vue')
  },
  {
    path: "/equipamentos_alarme_homologados.pdf",
    name: 'alarmesHomologados',
    component: () => import('../views/AlarmesHomologadosView.vue')
  },
  {
    path:"/TERMO-DE-USO.pdf",
    name: 'termoDeUso',
    component: () => import('../views/TermoView.vue')
  },
  {
    path:"/controleLicenciamento",
    name: 'controleLicenciamento',
    component: () => import('../views/ControleLicenciamentoView.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import('../views/HomeView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
